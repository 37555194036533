import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostFeedComponent } from './post-feed/post-feed.component';
import { RouterModule } from '@angular/router';
import { PostComponent } from './post/post.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TYTCommonModule } from '@tytapp/common';
import { TopicsModule } from '@tytapp/topics';
import { PostFormComponent } from './post-form/post-form.component';
import { FormsModule } from '@angular/forms';
import { ApiModule } from '@tytapp/api';
import { UserModule } from '@tytapp/user';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BantaSdkModule } from '@banta/sdk';
import { FollowRecommendationsComponent } from './follow-recommendations/follow-recommendations.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FollowButtonComponent } from './follow-button/follow-button.component';
import { FollowsService } from './follows.service';
import { FollowIndicatorComponent } from './follow-indicator/follow-indicator.component';
import { FollowIconComponent } from './follow-icon.component';
import { CommonUiModule } from '@tytapp/common-ui';
import { LikeButtonComponent } from './like-button/like-button.component';
import { LikesService } from './likes.service';
import { MatDividerModule } from '@angular/material/divider';
import { ActivitiesFeedComponent } from './activities-feed/activities-feed.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MediaPlaybackModule } from '@tytapp/media-playback';
import { ProductionPostComponent } from './production-post/production-post.component';
import { PollPostComponent } from './poll-post/poll-post.component';
import { PollsModule } from '@tytapp/polls';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatChipsModule } from '@angular/material/chips';
import { CommentingDisabledComponent } from './commenting-disabled.component';
import { RecommendedFollowsComponent } from './recommended-follows/recommended-follows.component';
import { FeaturedPollComponent } from '@tytapp/social/featured-poll/featured-poll.component';
import { PostBylineComponent } from '@tytapp/social/post-byline/post-byline.component';
import { HashtagLinkerPipe } from '@tytapp/social/hashtags/hashtag-linker.pipe';
import { ArticlesModule } from '@tytapp/articles';
import { PollEditorModule } from '@tytapp/poll-editor';

const COMPONENTS = [
    PostFeedComponent,
    ProductionPostComponent,
    PostFormComponent,
    FollowRecommendationsComponent,
    ProfileEditorComponent,
    FollowButtonComponent,
    FollowIndicatorComponent,
    FollowIconComponent,
    LikeButtonComponent,
    PostComponent,
    ActivitiesFeedComponent,
    PollPostComponent,
    CommentingDisabledComponent,
    FeaturedPollComponent,
    RecommendedFollowsComponent,
    PostBylineComponent,
    HashtagLinkerPipe
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ApiModule,
        UserModule,
        BantaSdkModule,
        TYTCommonModule,
        CommonUiModule,
        ArticlesModule,
        MatMenuModule,
        TopicsModule,
        PollsModule,
        PollEditorModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatButtonModule,
        MatTabsModule,
        MediaPlaybackModule,
        MatDividerModule,
        MatChipsModule,
        PortalModule,
        OverlayModule
    ],
    providers: [
        FollowsService,
        LikesService
    ]
})
export class SocialModule { }
