<page-context [page]="this">
  @if (user && !user.profile) {
    <div class="my-profile">
      <em>Error: An issue with your account prevents you from editing your profile. Please contact support&#64;tytnetwork.com
      and paste the following information in your email:</em>
      <br /><br />
      <pre>
        engn-escalate:profile.upm
      account: {{user.uuid}}</pre>
    </div>
  }

  @if (user && user.profile) {
    <div class="my-profile">
      <div class="notice">
        <app-notice></app-notice>
      </div>
      @if (coverStep === 'default') {
        <tyt-photo-uploader [user]="user" [aspectRatio]="1 / 1" [resizeToWidth]="600"
          [currentImage]="user.profile.avatar" [showCircularPreviews]="true" (saved)="saveAvatar($event)"
          (cleared)="clearAvatar()" (stepChanged)="avatarStep = $event" label="Upload your Profile Picture"
        guidance="Photo should be at least 600px x 600px"></tyt-photo-uploader>
      }
      <!-- COVER PHOTO: removed in TYT Nation R1 -->
      <!--
        @if (avatarStep === 'default') {
          <tyt-photo-uploader
            [user]="user"
            [aspectRatio]="1941 / 281"
            [resizeToWidth]="1941"
            [currentImage]="user.profile.cover_photo"
            (stepChanged)="coverStep = $event"
            (saved)="saveCover($event)"
            (cleared)="clearCover()"
            label="Upload your Cover Photo"
            guidance="Photo should be at least 1200px x 600px"
            />
        }
    -->
    @if (avatarStep === 'default' && coverStep === 'default') {
      <app-form #form action="/settings/profile" [handler]="this"
        class="form-fields">
        @if (submitting) {
          <div class="submitting">
            <h1>Saving changes...</h1>
            <app-spinner class="inline"></app-spinner>
          </div>
        }
        <div class="fields" [class.hidden]="submitting">
          <div class="form-section">
            <div class="form-section-header">
              <mat-icon>expand_more</mat-icon>
              Profile
            </div>
            <div class="form-container">
              <div class="form-group text-field">
                <label for="displayName">Display Name</label>
                <input errorHighlight [disabled]="submitting" type="text" class="form-control"
                  id="displayName" name="displayName"
                  [(ngModel)]="displayName" maxlength="50" />
                  <div class="informational">
                    <mat-icon>info</mat-icon>
                    Shown in your profile, and may be used for on-air shout-out.
                  </div>
                </div>
                <div class="form-group text-field">
                  <label for="displayName">Location</label>
                  <input errorHighlight [disabled]="submitting" type="text" class="form-control"
                    id="location" name="location"
                    [(ngModel)]="location" />
                    <div class="informational">
                      <mat-icon>info</mat-icon>
                      Shown in your profile, and may be used for on-air shout-out.
                      Use this to find other Nation members in your area for organizing direct action!
                    </div>
                  </div>
                  <div class="form-group text-field full-width">
                    <label for="about-me">About Me</label>
                    <textarea class="form-control" [disabled]="submitting" rows="4" id="about-me"
                      [attr.maxlength]="user.staff ? 99999999 : 1000"
                    name="aboutMe" [(ngModel)]="aboutMe"></textarea>
                  </div>
                  <div class="form-group pronouns">
                    <label>Pronouns</label>
                    <mat-select [(ngModel)]="pronouns" [disabled]="submitting" >
                      <mat-option [value]="null">Unspecified</mat-option>
                      @for (pronoun of availablePronouns; track pronoun) {
                        <mat-option [value]="pronoun.label">
                          {{ pronoun.label }}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                  <div class="form-group text-field">
                    <label for="tagline">Tagline</label>
                    <input errorHighlight type="text" [disabled]="submitting" class="form-control" id="tagline" name="tagline" [(ngModel)]="tagline" />
                  </div>
                  <div class="form-group text-field">
                    <label for="inspirational-figure">Favorite Inspirational Figure</label>
                    <input errorHighlight type="text" [disabled]="submitting" class="form-control" id="inspirational-figure" name="inspirational-figure"
                      [(ngModel)]="favoriteInspirationalFigure" />
                    </div>
                    <div class="form-group text-field">
                      <label for="favorite-quote">Favorite Quote</label>
                      <input errorHighlight type="text" [disabled]="submitting" class="form-control" id="favorite-quote" name="favorite-quote"
                        [(ngModel)]="favoriteQuote" />
                      </div>
                      <div class="form-group text-field">
                        <label for="favorite-tyt-memory">Favorite TYT Memory</label>
                        <input errorHighlight type="text" [disabled]="submitting" class="form-control" id="favorite-tyt-memory" name="favorite-tyt-memory"
                          [(ngModel)]="favoriteTYTMemory" />
                        </div>
                        <div class="form-group text-field">
                          <label for="political-views">Political Views</label>
                          <input errorHighlight type="text" [disabled]="submitting" class="form-control" id="political-views" name="political-views"
                            [(ngModel)]="politicalViews" />
                          </div>
                        </div>
                      </div>
                      <div class="form-section">
                        <div class="form-section-header">
                          <mat-icon>expand_more</mat-icon>
                          Social Links
                        </div>
                        <div class="form-container">
                          <div class="form-group text-field">
                            <label for="twitterHandle">Twitter Handle</label>
                            <input errorHighlight type="text"
                              [disabled]="submitting"
                              class="form-control"
                              id="twitterHandle"
                              name="twitterHandle"
                              pattern="[A-Za-z0-9_]+"
                              [(ngModel)]="twitterHandle"
                              (blur)="sanitizeTwitterHandle()">
                            </div>
                            <div class="form-group text-field">
                              <label for="instagramHandle">Instagram Handle</label>
                              <input errorHighlight type="text"
                                class="form-control"
                                [disabled]="submitting"
                                id="instagramHandle" name="instagramHandle"
                                [(ngModel)]="instagramHandle"
                                (blur)="sanitizeInstagramHandle()">
                              </div>
                              <div class="form-group text-field">
                                <label for="mastodonHandle">Mastodon Handle</label>
                                <input errorHighlight type="text"
                                  class="form-control"
                                  [disabled]="submitting"
                                  id="mastodonHandle" name="mastodonHandle"
                                  [(ngModel)]="mastodonHandle"
                                  pattern="@.+@.+\..+"
                                  maxlength="150"
                                  (blur)="sanitizeMastodonHandle()">
                                </div>
                                <div class="form-group text-field">
                                  <label for="facebookUrl">Facebook URL</label>
                                  <input errorHighlight type="text" class="form-control"
                                    id="facebook-url" name="facebookUrl"
                                    [disabled]="submitting"
                                    pattern="https://(www\.)?facebook.com/([^/]+)\/?"
                                    [(ngModel)]="facebookUrl">
                                  </div>
                                  <div class="form-group text-field">
                                    <label for="linkedInHandle">LinkedIn URL</label>
                                    <input errorHighlight
                                      type="text"
                                      class="form-control"
                                      id="linkedin-handle" name="linkedInHandle"
                                      [disabled]="submitting"
                                      [(ngModel)]="linkedInHandle">
                                    </div>
                                  </div>
                                </div>
                                <div class="privacy-alert">
                                  <mat-icon>privacy_tip</mat-icon>
                                  <p>The following information will always be kept private and is only visible to you, regardless of how
                                    you've set up your <a routerLink="/settings/privacy-controls">privacy controls.</a>
                                  </p>
                                </div>
                                <div class="form-section">
                                  <div class="form-section-header">
                                    <mat-icon>expand_more</mat-icon>
                                    Private information
                                  </div>
                                  <div class="form-container">
                                    <div class="form-group text-field">
                                      <label for="firstName">First Name</label>
                                      <div class="informational">
                                        <mat-icon>info</mat-icon>
                                        Names are collected to provide accurate billing and support information.
                                      </div>
                                      <input
                                        errorHighlight
                                        type="text"
                                        class="form-control"
                                        id="first-name"
                                        name="firstName"
                                        [disabled]="submitting"
                                        [(ngModel)]="firstName" />
                                        <tyt-privacy-status state="private"></tyt-privacy-status>
                                      </div>
                                      <div class="form-group text-field">
                                        <label for="lastName">Last Name</label>
                                        <div class="informational">
                                          <mat-icon>info</mat-icon>
                                          Names are collected to provide accurate billing and support information.
                                        </div>
                                        <input
                                          errorHighlight
                                          type="text"
                                          class="form-control"
                                          id="last-name"
                                          name="lastName"
                                          [(ngModel)]="lastName"
                                          [disabled]="submitting"
                                          />
                                          <tyt-privacy-status state="private"></tyt-privacy-status>
                                        </div>
                                        <div class="form-group birthdate-container">
                                          <label>Birthdate</label>
                                          <div class="informational">
                                            <mat-icon>info</mat-icon>
                                            Birthdates are collected for sending annual birthday reminders and special offers.
                                          </div>
                                          <div class="birthdate">
                                            <div class="month">
                                              <input errorHighlight
                                                maxlength="2"
                                                placeholder="MM"
                                                type="text"
                                                class="form-control"
                                                id="month"
                                                [disabled]="submitting"
                                                name="month"
                                                [(ngModel)]="month" />
                                              </div>
                                              <div class="day">
                                                <input
                                                  errorHighlight
                                                  maxlength="2"
                                                  placeholder="DD"
                                                  type="text"
                                                  class="form-control"
                                                  id="day"
                                                  name="day"
                                                  [disabled]="submitting"
                                                  [(ngModel)]="day" />
                                                </div>
                                                <div class="year">
                                                  <input
                                                    errorHighlight
                                                    maxlength="4"
                                                    placeholder="YYYY"
                                                    type="text"
                                                    class="form-control"
                                                    id="year"
                                                    name="year"
                                                    [disabled]="submitting"
                                                    [(ngModel)]="year" />
                                                  </div>
                                                </div>
                                                <tyt-privacy-status state="private"></tyt-privacy-status>
                                              </div>
                                            </div>
                                            <h1>Address</h1>
                                            <div class="informational">
                                              <mat-icon>info</mat-icon>
                                              Address is used for campaigns and to help improve our impact.
                                            </div>
                                            <div class="form-container">
                                              <div class="form-group text-field">
                                                <label for="city">Line 1</label>
                                                <input
                                                  errorHighlight
                                                  type="text"
                                                  class="form-control"
                                                  id="address1"
                                                  name="address1"
                                                  [disabled]="submitting"
                                                  [(ngModel)]="address1">
                                                  <tyt-privacy-status state="private"></tyt-privacy-status>
                                                </div>
                                                <div class="form-group text-field">
                                                  <label for="city">Line 2</label>
                                                  <input
                                                    errorHighlight
                                                    type="text"
                                                    class="form-control"
                                                    id="address2"
                                                    name="address2"
                                                    [disabled]="submitting"
                                                    [(ngModel)]="address2">
                                                    <tyt-privacy-status state="private"></tyt-privacy-status>
                                                  </div>
                                                  <div class="form-group text-field city">
                                                    <label for="city">City</label>
                                                    <input
                                                      errorHighlight
                                                      placeholder="e.g. Los Angeles"
                                                      type="text"
                                                      class="form-control"
                                                      id="city"
                                                      name="city"
                                                      [disabled]="submitting"
                                                      [(ngModel)]="city">
                                                      <tyt-privacy-status state="private"></tyt-privacy-status>
                                                    </div>
                                                    <div class="form-group text-field">
                                                      <label for="state">State / Province</label>
                                                      <input
                                                        errorHighlight
                                                        maxlength="2"
                                                        placeholder="e.g. CA"
                                                        type="text"
                                                        class="form-control"
                                                        id="state"
                                                        name="state"
                                                        [disabled]="submitting"
                                                        [(ngModel)]="state">
                                                        <tyt-privacy-status state="private"></tyt-privacy-status>
                                                      </div>
                                                      <div class="form-group text-field">
                                                        <label for="zip">ZIP / Postal Code</label>
                                                        <input
                                                          errorHighlight
                                                          maxlength="20"
                                                          type="text"
                                                          class="form-control"
                                                          id="zip"
                                                          name="zip"
                                                          [disabled]="submitting"
                                                          [(ngModel)]="zip" />
                                                          <tyt-privacy-status state="private"></tyt-privacy-status>
                                                        </div>
                                                        <div class="form-group text-field">
                                                          <label for="country">Country</label>
                                                          <mat-select name="country" id="country" [(ngModel)]="country" [disabled]="submitting">
                                                            @if (countries) {
                                                              <ng-container ?>
                                                                @for (country of countries; track country) {
                                                                  <mat-option [value]="country.code">
                                                                    {{country.name}}
                                                                  </mat-option>
                                                                }
                                                              </ng-container>
                                                            }
                                                          </mat-select>
                                                          <tyt-privacy-status state="private"></tyt-privacy-status>
                                                        </div>
                                                      </div>
                                                      @if (enableDemographicsFields) {
                                                        <div class="form-container">
                                                          <div class="form-group text-field">
                                                            <label for="gender">Gender</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Gender is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="gender" name="gender" [(ngModel)]="gender" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="male">Man</mat-option>
                                                              <mat-option value="female">Woman</mat-option>
                                                              <mat-option value="trans">Transgender / Non-conforming</mat-option>
                                                              <mat-option value="non-binary">Non-Binary</mat-option>
                                                              <mat-option value="other">Other</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="race">Race</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Race is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="race" name="race" [(ngModel)]="race" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="native_american">Native American or Alaska Native</mat-option>
                                                              <mat-option value="asian">Asian</mat-option>
                                                              <mat-option value="black">Black or African American</mat-option>
                                                              <mat-option value="pacific_islander">Native Hawaiian or Other Pacific Islander</mat-option>
                                                              <mat-option value="white">White</mat-option>
                                                              <mat-option value="hispanic">Hispanic</mat-option>
                                                              <mat-option value="other">Other</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="race">Sexual Orientation</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Sexual orientation is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="sexuality" name="sexuality" [(ngModel)]="sexuality" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="heterosexual">Heterosexual</mat-option>
                                                              <mat-option value="bisexual">Bisexual</mat-option>
                                                              <mat-option value="homosexual">Homosexual</mat-option>
                                                              <mat-option value="asexual">Asexual</mat-option>
                                                              <mat-option value="pansexual">Pansexual</mat-option>
                                                              <mat-option value="other">Other</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="education">Highest Education Level Achieved</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Education level is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="education" name="education" [(ngModel)]="education" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="less-than-high-school">Less than a high school diploma</mat-option>
                                                              <mat-option value="high-school">High school disploma</mat-option>
                                                              <mat-option value="bachelors">Bachelors Degree / Technical Degress (eg. BA, BS)</mat-option>
                                                              <mat-option value="professional_degree">Professional Degree (eg. MD, DDS, DVM)</mat-option>
                                                              <mat-option value="masters">Master's Degree (eg. MA, MS, MEd)</mat-option>
                                                              <mat-option value="doctorate">Doctorate (eg. PhD, EdD)</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="income">Annual Household Income</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Income is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="income" name="income" [(ngModel)]="income" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="0/20000">Less than $20,000</mat-option>
                                                              <mat-option value="20001/34999">$20,001 to $34,999</mat-option>
                                                              <mat-option value="35000/49999">$35,000 to $49,999</mat-option>
                                                              <mat-option value="50000/74999">$50,000 to $74,999</mat-option>
                                                              <mat-option value="75000/99999">$75,000 to $99,999</mat-option>
                                                              <mat-option value="100000/149999">$100,000 to $149,999</mat-option>
                                                              <mat-option value="150000/199999">$150,000 to $199,999</mat-option>
                                                              <mat-option value="200000/Inf">Over $200,000</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="income">Party Affiliation</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Party affiliation is used for campaigns and to help improve our impact.
                                                            </div>
                                                            <mat-select id="party" name="party" [(ngModel)]="party" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="democrat">Democrat</mat-option>
                                                              <mat-option value="green_party">Green Party</mat-option>
                                                              <mat-option value="independent">Independent</mat-option>
                                                              <mat-option value="libertarian">Libertarian</mat-option>
                                                              <mat-option value="republican">Republican</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="private"></tyt-privacy-status>
                                                          </div>
                                                          <div class="form-group text-field">
                                                            <label for="religion-philosophy">Religion / Philosophy</label>
                                                            <div class="informational">
                                                              <mat-icon>info</mat-icon>
                                                              Religion / Philosophy is used for campaings and to help improve our impact.
                                                            </div>
                                                            <mat-select id="religionOrPhilosophy" name="religionOrPhilosophy" [(ngModel)]="religionOrPhilosophy" [disabled]="submitting">
                                                              <mat-option disabled selected value>Select One</mat-option>
                                                              <mat-option value="">Unspecified</mat-option>
                                                              <mat-option value="agnostic">Agnostic</mat-option>
                                                              <mat-option value="atheist">Atheist</mat-option>
                                                              <mat-option value="buddhist">Buddhist</mat-option>
                                                              <mat-option value="christian">Christian</mat-option>
                                                              <mat-option value="hindu">Hindu</mat-option>
                                                              <mat-option value="jewish">Jewish</mat-option>
                                                              <mat-option value="muslim">Muslim</mat-option>
                                                              <mat-option value="sikh">Sikh</mat-option>
                                                              <mat-option value="spiritual">Spiritual</mat-option>
                                                              <mat-option value="taoist">Taoist</mat-option>
                                                            </mat-select>
                                                            <tyt-privacy-status state="public"></tyt-privacy-status>
                                                          </div>
                                                        </div>
                                                      }
                                                    </div>
                                                    <error-message [producer]="form"></error-message>
                                                    @if (updateSuccessMessage) {
                                                      <div class="alert alert-success updateSuccessMessage">
                                                        {{updateSuccessMessage}}
                                                      </div>
                                                    }
                                                    <div class="actions">
                                                      <button type="button" mat-button (click)="cancelEdit()" [disabled]="submitting">Discard Changes</button>
                                                      <button mat-raised-button color="primary" class="save" [disabled]="submitting">
                                                        @if (submitting) {
                                                          <app-spinner class="inline line"></app-spinner>
                                                          Saving
                                                        }
                                                        @if (!submitting) {
                                                          Save
                                                        }
                                                      </button>
                                                    </div>
                                                  </div>
                                                </app-form>
                                              }
                                            </div>
                                          }
                                        </page-context>