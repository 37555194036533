import { Component, ElementRef, Input, OnDestroy, Output, ViewChild, inject } from '@angular/core';
import { ApiPost, ApiPostWithReplies, ApiUser, PostsApi } from '@tytapp/api';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '@tytapp/common';

const MAP_KEY = Symbol('map');

interface Filter {
    key: string;
    label: string;
    id: string;
}

@Component({
    selector: 'tyt-post-feed',
    templateUrl: './post-feed.component.html',
    styleUrls: ['./post-feed.component.scss']
})
export class PostFeedComponent implements OnDestroy {

    private postApi = inject(PostsApi);
    private userService = inject(UserService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private logger = inject(LoggerService);

    constructor() {
        this.userService.userChanged.subscribe(user => this.user = user);
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(qp => {
            this.userTypeFilter = qp.get('userType') ?? this.userTypeFilters[0].key;
            this.postTypeFilter = qp.get('postType') ?? this.postTypeFilters[0].key;
            this.refreshFeed();
        });
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
    }

    ngAfterViewInit() {
        this.createObserver();
    }

    user: ApiUser;
    postTypeFilters: Filter[] = [
        { key: 'all', id: ':all', label: 'All Posts' },
        { key: 'media', id: 'CMS::Production', label: 'Media' },
        { key: 'polls', id: 'ChooseOnePoll', label: 'Polls' },
        { key: 'petitions', id: 'Petition', label: 'Petitions' },
        { key: 'articles', id: 'CMS::Article', label: 'Articles' },
    ];

    userTypeFilters: Filter[] = [
        { key: 'all', id: ':all', label: 'All Accounts' },
        { key: 'official', id: ':official', label: 'Official Accounts' }
    ];

    postTypeFilter = this.postTypeFilters[0].key;
    userTypeFilter = this.userTypeFilters[0].key;

    @Input() showFilters = true;
    @Input() showTypeFilter = true;
    @Input() showUserFilter = true;
    @Input() showHeader = true;
    @Input() headerLabel = 'Feed';

    private _slug: string;
    @Input() get slug() { return this._slug; }
    set slug(value) {
        if (this._slug === value)
            return;

        this._slug = value;
        setTimeout(() => this.refreshFeed());
    }

    setFilter(filterID: string) {
        this.state = 'loading';
        this.postTypeFilter = filterID;
        this.refreshFeed();
    }

    private _feedRefreshOperation: Promise<void>;
    async refreshFeed() {
        await this._feedRefreshOperation;
        await (this._feedRefreshOperation = this.performFeedRefresh());
    }

    async performFeedRefresh() {
        this.state = 'loading';
        this.hasMore = true;
        this.posts = null;
        this.nextPage = 1;
        await this.fetchFeed();
    }

    addPost(post: ApiPost) {
        this.posts.unshift(post);
    }
    nextPage = 1;
    posts: ApiPost[] = null;
    postMap = new Map<number, ApiPost>();

    @Input() emptyMessage = 'Nothing to show here';
    @Input() hintMessage = '';
    @Input() hintIcon = 'star';

    @Output() postDeleted = new Subject<ApiPost>();

    onPostDeleted(post: ApiPost) {
        this.posts = this.posts.filter(x => x !== post);
        this.postDeleted.next(post);
    }

    activatePost(post: ApiPost) {
        this.posts.forEach(p => (p['$transient'] ??= {}) && (p['$transient'].activated = false));
        post['$transient'].activated = true;
    }

    integratePosts(existingPosts: ApiPost[], newPosts: ApiPostWithReplies[], dir: 'top' | 'bottom' = 'bottom') {
        let map: Map<number, ApiPostWithReplies>;

        if (!(existingPosts as any).__map) {
            map = (existingPosts as any).__map = new Map();
            existingPosts.forEach(p => map.set(p.id, p));
        } else {
            map = (existingPosts as any).__map;
        }

        // If the parent is in a batch along with its replies, then the replies should
        // be adopted by the parent.

        let adoptedPostIDs: number[] = [];
        for (let post of newPosts) {
            if (!post.parent_id)
                continue;
            let parentPost = newPosts.find(x => x.id === post.parent_id);
            if (!parentPost)
                continue;

            // Do not include replies-to-replies though
            // if (parentPost.parent_id)
            //     continue;

            parentPost.replies ??= [];
            parentPost.replies.push(post);
            parentPost.replies.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

            adoptedPostIDs.push(post.id);
        }
        newPosts = newPosts.filter(x => !adoptedPostIDs.includes(x.id));

        for (let post of newPosts) {
            let existingPost = map.get(post.id);
            if (existingPost) {
                existingPost.replies ??= [];
                this.integratePosts(existingPost.replies, post.replies ?? []);
                post.replies = existingPost.replies;
                Object.assign(existingPost, post);
            } else {
                if (dir === 'bottom')
                    existingPosts.push(post);
                else
                    existingPosts.unshift(post);

                map.set(post.id, post);
            }
        }
    }

    cachedFeeds = new Map<string, ApiPost[]>();

    get cacheKey() {
        return `${this.slug}/${this.userTypeFilter}/${this.postTypeFilter}`;
    }

    loadingMore = false;
    error = false;
    hasMore = true;

    async fetchFeed() {
        if (this.loadingMore || !this.hasMore)
            return;

        await this.userService.ready;
        this.loadingMore = true;
        try {

            if (this.cachedFeeds.has(this.cacheKey) && this.nextPage === 1) {
                this.posts = this.cachedFeeds.get(this.cacheKey);
                this.state = 'loaded';
                this.nextPage += 1;
                return;
            }

            let posts: ApiPost[];
            try {
                if (this.slug === ':community') {
                    posts = <ApiPost[]><unknown>await this.postApi.getCommunityPosts(
                        undefined, this.nextPage++,
                        this.postTypeFilters.find(x => x.key === this.postTypeFilter)?.id,
                        this.userTypeFilters.find(x => x.key === this.userTypeFilter)?.id
                    ).toPromise();
                } else if (this.slug === ':home') {
                    posts = <ApiPost[]> <unknown> await this.postApi.getHomePosts(
                        undefined, this.nextPage++,
                        this.postTypeFilters.find(x => x.key === this.postTypeFilter)?.id,
                        this.userTypeFilters.find(x => x.key === this.userTypeFilter)?.id
                    ).toPromise();
                } else {
                    posts = <ApiPost[]> <unknown> await this.postApi.getUserPosts(
                        this.slug, this.nextPage++,
                        this.postTypeFilters.find(x => x.key === this.postTypeFilter)?.id,
                        this.userTypeFilters.find(x => x.key === this.userTypeFilter)?.id
                    ).toPromise();
                }

                this.hasMore = posts.length > 0;
                this.posts ??= [];
                this.integratePosts(this.posts, posts);
                this.cachedFeeds.set(this.cacheKey, this.posts);
                this.state = 'loaded';
            } catch (e) {
                if (e.json)
                    e = e.json();

                if (e.code === 'not-allowed') {
                    if (this.userService.user)
                        this.state = 'private';
                    else
                        this.state = 'requires-login';
                } else {
                    this.state = 'error';
                    this.logger.error(`Caught error while fetching post feed named '${this.slug}':`);
                    this.logger.error(e);
                    this.error = true;
                }
            }
        } finally {
            this.loadingMore = false;
        }
    }

    state = 'loading';

    @ViewChild('feedContainerEnd') feedContainerEnd: ElementRef;

    observer: IntersectionObserver;
    intersectDebounce = false;
    intersectDebounceTimer;
    createObserver() {
        this.observer = new IntersectionObserver(async entries => {
            if (this.state !== 'loaded' || this.intersectDebounce)
                return;

            clearTimeout(this.intersectDebounceTimer);
            let entry = entries[entries.length - 1];
            if (entry.isIntersecting) {
                this.intersectDebounce = true;
                try {
                    await this.fetchFeed();
                } finally {
                    this.intersectDebounceTimer = setTimeout(() => {
                        this.intersectDebounce = false;
                    }, 100);
                }
            }
        }, {
            rootMargin: '0px 0px 100px 0px',
        });

        this.observer.observe(this.feedContainerEnd.nativeElement);
    }

    get isUserHome() {
        return this.user?.username === 'home';
    }

    updateFilter(filter: 'userType' | 'postType') {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                [filter]: this[`${ filter }Filter`]
            },
            queryParamsHandling: 'merge'
        });
    }
}
